import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

const ShowLanguageModal = ({ showModal, onHide, data, onremove }) => {
  const navigate = useNavigate();

  const openLanguage = (data) => {
    navigate(`/pdf-extract`, { state: { data } });
  };

  return (
    <Modal
      show={showModal}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Choose Book Language
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-wrap justify-content-evenly">
  {[...new Map(data?.pdfs?.map(item => [item.language, item])).values()]?.map((item, index) => (
    <Button onClick={() => openLanguage(item)} key={index}>
      {item.language}
    </Button>
  ))}
</div>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowLanguageModal;
