import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { baseUrl } from "../../constant/base_url";
import Spinner from 'react-bootstrap/Spinner';
import './style.css'



const FeedbackModal = ({ showModal, onHide, bookId }) => {
  const [loader, setLoader] = useState(false)
  const formik = useFormik({
    initialValues: {
      email: "",
      message: "",
      reaction: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid Email address")
        .test(
          'is-required-if-filled',
          'Email is Required',
          function (value) {
            if (value && value.trim() === "") {
              return this.createError({ message: 'Email is Required' });
            }
            return true;
          }
        ),
      message: Yup.string().required("Feedback is Required"),
      reaction: Yup.string().required("Reaction is Required"),
    }),
  });

  const addFeedback = async () => {
    const payload = { ...formik.values, book_id: bookId }
    setLoader(true)
    try {
      const { data } = await axios.post(`${baseUrl}/feedback`, payload)
      if (data?.status === "sucess") {
        onHide()
      }
      setLoader(false)
    }
    catch (err) {
      setLoader(false)
    }
  }

  return (
    <Modal
      size="lg"
      show={showModal}
      centered
      className="modal-feedback"
    >
      <Modal.Body>
        <div>
          <h4 className="fw-bold text-center">Your Feedback Helps Us Get Better!</h4>
          <p className="text-center">Tell us what you think about this story!</p>
        </div>
        <div className="d-flex justify-content-center emoji-container">
          <div className="me-5 text-center" onClick={() => formik.setFieldValue('reaction', 'Happy')}>
            <span className={`emoji-icon ${formik.values.reaction === 'Happy' && 'selected-emoji'}`} >😍</span>
            <p>Loved it!</p>
          </div>
          <div className="me-5 text-center" onClick={() => formik.setFieldValue('reaction', 'Sad')}>
            <span className={`emoji-icon ${formik.values.reaction === 'Sad' && 'selected-emoji'}`} >🙂</span>
            <p>Its Good. </p>
          </div>
          <div className="me-5 text-center" onClick={() => formik.setFieldValue('reaction', 'Worse')}>
            <span className={`emoji-icon ${formik.values.reaction === 'Worse' && 'selected-emoji'}`}>🤔</span>
            <p>Just Okay.</p>
          </div>
        </div>
        <div className="mt-4">
          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email address"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.email && !!formik.errors.email
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-3"
          >
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Type your feedback here…"
              id="message"
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.message && !!formik.errors.message
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.message}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="text-center">
          <Button className="me-2" variant="dark" disabled={!formik.isValid || !formik.dirty} onClick={addFeedback}>{loader ? <Spinner animation="border" /> : "Submit"}</Button>
          <Button className="me-2" variant="light" onClick={() => window.location.href = "/"}> Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FeedbackModal;
